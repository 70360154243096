
html,
body,
div,
span,
applet,
object,
iframe,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font: inherit;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Luckiest Guy";
  src: local("Luckiest Guy"), url(luckiest-guy-v17-latin-regular.woff) format("woff");
  font-family: "Caveat Brush";
  src: local("Caveat Brush"), url(caveat-brush-v9-latin-regular.woff) format("woff");
}

body {
  line-height: 1;
  margin: 0;
  font-family: "Caveat Brush",cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

h1 {
  
  padding-right: 25px;
  padding-left: 25px;
  font-family: "Luckiest Guy",cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:3em;
  font-weight: normal;
}

h2 {
  padding-right: 25px;
  padding-left: 25px;
  font-family: "Luckiest Guy",cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:1.8em;
  font-weight: normal;
}

h3 {
  padding-right: 10px;
  padding-left: 10px;
  font-family: "Luckiest Guy",cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:1.6em;
  font-weight: normal;
}

h4 {
  padding-right: 25px;
  padding-left: 25px;
  font-family: "Luckiest Guy",cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:1.4em;
  font-weight: normal;
}

h5 {
  font-family: "Luckiest Guy",cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:1.2em;
  font-weight: normal;
}

h6 {
  font-family: "Luckiest Guy",cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:1em;
  font-weight: normal;
}

p {
  font-family: "Caveat Brush",cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:1.3em;
  font-weight: normal;
  
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

code {
  font-family: "Caveat Brush",cursive;
  font-size:2.25em;
}

html {
  background-color: "#000a25";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
